import { logger } from '../../logger'
import {
    AdminOrganizationManagementService,
    ApiError,
    ErrorResponse,
    OrganizationStatus,
    UuidV4,
    PendingInvoicesOrganizationsResponse,
    CofaceStatus,
    OrganizationService,
    OrganizationType,
    RegistrationService,
    RegistrationState,
    UserStatus,
} from '@lazr/openapi-client'
import { Organization, OrganizationPermission, OrganizationRole, OtherOrganization } from '../../model'
import { handleUnauthorizedException } from './index'
import { OrganizationList } from '../../model/Organization'
import { OrganizationPermissionList, OrganizationRoleList } from '../../model/OrganizationPermission'

export type OrganizationsOrderByField =
    'name' |
    'adminEmail'|
    'adminName' |
    'createdAt' |
    'repName' |
    'cofaceStatus' |
    'cofaceCreditLimit' |
    'maxFreightOrders' |
    'maxParcelOrders' |
    'creditLimit' |
    'creditInUseCad' |
    'lastLoginAt' |
    'lastGhostAt' |
    'lastOrderAt' |
    'numberOfUsers' |
    'registerUrl' |
    undefined

export type OrganizationsOrder = 'asc' | 'desc'

export interface OrganizationsFilter {
    organizationName?: string
    organizationStatuses?: OrganizationStatus[]
    searchField?: string
    orderBy?: OrganizationsOrderByField
    order?: OrganizationsOrder
    salesRepId?: UuidV4
    adminStatus?: UserStatus
    isGmx?: boolean
}
export interface OrganizationsPaging {
    page?: number
    resultPerPage?: number
}

export interface EditOrganizationRequest {
    id: UuidV4
    cofaceStatus?: CofaceStatus | null
    cofaceCreditLimit?: number | null
    maxFreightOrders?: number | null
    maxParcelOrders?: number | null
    creditLimit?: number | null
    salesRep?: UuidV4 | null
}

export const OrganizationApiService = Object.freeze({
    approve: async (id: string): Promise<void> => {
        try {
            await AdminOrganizationManagementService.approveOrganization({ id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('approve', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Approve failed')
            }
            throw new Error('Approve failed')
        }
    },
    evaluate: async (id: string): Promise<void> => {
        try {
            await AdminOrganizationManagementService.evaluateOrganization({ id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('evaluate', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Evaluate failed')
            }
            throw new Error('Evaluate failed')
        }
    },
    block: async (id: string, blockedReason: string): Promise<void> => {
        try {
            await AdminOrganizationManagementService.blockOrganization({ id, blockedReason })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('block', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Block failed')
            }
            throw new Error('Block failed')
        }
    },
    deactivate: async (id: string): Promise<void> => {
        try {
            await AdminOrganizationManagementService.deactivateOrganization({ id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('deactivate', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Deactivate failed')
            }
            throw new Error('Deactivate failed')
        }
    },
    delete: async (id: string): Promise<void> => {
        try {
            await AdminOrganizationManagementService.deleteOrganization({ id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('delete', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Delete failed')
            }
            throw new Error('Delete failed')
        }
    },
    register: async (
        name: string,
        username: string,
        password: string,
        registerUrl: string,
        firstName: string,
        lastName: string,
        phone: string,
        preferedLanguage: string,
        industry: string,
        volumeOfShipments: string,
        transportationServicesUsed: string,
        repReference?: {
            affiliateId?: UuidV4,
            repName?: string,
        }
    ): Promise<{ state:RegistrationState; error?: string}> => {
        const reqBody = {
            organizationName: name,
            organizationType: OrganizationType.CLIENT,
            username: username,
            password: password,
            repReference: repReference,
            registerUrl: registerUrl,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            preferedLanguage: preferedLanguage,
            industry: industry,
            volumeOfShipments: volumeOfShipments,
            transportationServicesUsed: transportationServicesUsed,
        }

        try {
            const result = await RegistrationService.registerOrganization(reqBody)

            return {
                state: result?.data?.registrationState ?? RegistrationState.FAILED,
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse

                return {
                    state: RegistrationState.FAILED,
                    error: errorResponse.error?.message ?? 'Unable to register organization',
                }
            }

            return {
                state: RegistrationState.FAILED,
                error: 'Unable to register organization',
            }
        }
    },
    list: async (paging?: OrganizationsPaging, filters?: OrganizationsFilter, withDetail = false, namesAndIdsOnly = false): Promise<OrganizationList> => {
        let getOrganizationsResponse

        console.log('LOUYS - HERE 1')


        try {
            getOrganizationsResponse = await OrganizationService.getOrganizations(
                paging?.page,
                paging?.resultPerPage,
                filters?.orderBy,
                filters?.order,
                filters?.organizationName,
                filters?.organizationStatuses,
                filters?.salesRepId,
                withDetail,
                filters?.adminStatus,
                undefined,
                filters?.isGmx,
                namesAndIdsOnly,
            )
        } catch (error: any) {
            console.log('LOUYS - HERE')
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve organization list')
            }
            throw new Error('Unable to retrieve organization list')
        }

        const organizationList = getOrganizationsResponse.data
        if (!organizationList) {
            logger.debug('list', JSON.stringify(getOrganizationsResponse, null, 4))
            throw new Error('Unable to retrieve organization list')
        }

        return {
            organizations: organizationList.organizations.map((organization) => new Organization(organization)),
            total: organizationList.paging?.items,
        }
    },
    listLinked: async (): Promise<OtherOrganization[]> => {
        let getOrganizationsResponse
        try {
            getOrganizationsResponse = await OrganizationService.getLinkedOrganizations()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('listLinked', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve list linked organization')
            }
            throw new Error('Unable to retrieve linked organization')
        }

        if (!getOrganizationsResponse.data) {
            logger.debug('listLinked', JSON.stringify(getOrganizationsResponse, null, 4))
            throw new Error('Unable to retrieve linked organization')
        }

        return getOrganizationsResponse.data.map((organization) => new OtherOrganization(organization))
    },
    getCurrent: async (): Promise<Organization> => {
        let getCurrentOrganization
        try {
            getCurrentOrganization = await OrganizationService.getCurrentOrganization()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getCurrent', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve current organization')
            }
            throw new Error('Unable to retrieve organization')
        }

        const currentOrganization = getCurrentOrganization.data

        if (!currentOrganization) {
            logger.debug('getCurrent', JSON.stringify(getCurrentOrganization, null, 4))
            throw new Error('Unable to retrieve current organization')
        }

        return new Organization(currentOrganization)
    },
    getById: async (id: UuidV4): Promise<Organization> => {
        let getOrganizationByIdResponse
        try {
            getOrganizationByIdResponse = await OrganizationService.getOrganizationById(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve organization by id')
            }
            throw new Error('Unable to retrieve organization by id')
        }

        if (!getOrganizationByIdResponse.data) {
            logger.debug('getById', JSON.stringify(getOrganizationByIdResponse, null, 4))
            throw new Error('Unable to retrieve organization')
        }

        return new Organization(getOrganizationByIdResponse.data)
    },
    getBySlug: async (slug: string): Promise<Organization> => {
        const ERROR_MESSAGE = 'Unable to retrieve organization'
        let response

        try {
            response = await OrganizationService.getOrganizationBySlug(slug)
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || ERROR_MESSAGE)
            }
        }

        if (response && response.data) {
            return new Organization(response.data)
        } else {
            throw new Error(ERROR_MESSAGE)
        }
    },
    getPendingInvoicesOrganizations: async (): Promise<PendingInvoicesOrganizationsResponse> => {
        let getOrganizationsResponse
        try {
            getOrganizationsResponse = await OrganizationService.getPendingInvoicesOrganizations()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getPending', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve pending invoice organization')
            }
            throw new Error('Unable to retrieve pending invoice organization')
        }

        if (!getOrganizationsResponse.data) {
            logger.debug('getPending', JSON.stringify(getOrganizationsResponse, null, 4))
            throw new Error('Unable to retrieve pending invoice organization')
        }

        return getOrganizationsResponse.data
    },
    edit: async (organizationAttributes: EditOrganizationRequest): Promise<void> => {
        try {
            await OrganizationService.editOrganization(organizationAttributes)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('edit', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to update organization')
            }
            throw new Error('Unable to update organization')
        }
    },
    organizationPermissions: async (): Promise<OrganizationPermissionList> => {
        let getOrganizationPermissionsResponse
        try {
            getOrganizationPermissionsResponse = await OrganizationService.getOrganizationPermissions()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('organizationPermissions', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve organization permissions')
            }
            throw new Error('Unable to retrieve organization permissions')
        }

        const organizationPermissions = getOrganizationPermissionsResponse.data
        if (!organizationPermissions) {
            logger.debug('list', JSON.stringify(getOrganizationPermissionsResponse, null, 4))
            throw new Error('Unable to retrieve organization permissions')
        }

        return {
            organizationPermissions: organizationPermissions.organizationPermissions
                .map((organizationPermission) => new OrganizationPermission(organizationPermission)),
        }
    },
    organizationRoles: async (organizationId: UuidV4): Promise<OrganizationRoleList> => {
        let getOrganizationRolesResponse
        try {
            getOrganizationRolesResponse = await OrganizationService.getOrganizationRoles(organizationId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('organizationRoles', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve organization roles')
            }
            throw new Error('Unable to retrieve organization roles')
        }

        const organizationRoles = getOrganizationRolesResponse.data
        if (!organizationRoles) {
            logger.debug('organizationRoles', JSON.stringify(getOrganizationRolesResponse, null, 4))
            throw new Error('Unable to retrieve organization roles')
        }

        return {
            organizationRoles: organizationRoles.organizationRoles
                .map((organizationRole) => new OrganizationRole(organizationRole)),
        }
    },
    userOrganizationsByUserId: async (userId: UuidV4): Promise<OrganizationList> => {
        let getUserOrganizationsResponse
        try {
            getUserOrganizationsResponse = await OrganizationService.getUserOrganizationsByUserId(userId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('organizationPermissions', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve organization permissions')
            }
            throw new Error('Unable to retrieve organization permissions')
        }

        const userOrganizations = getUserOrganizationsResponse.data
        if (!userOrganizations) {
            logger.debug('list', JSON.stringify(getUserOrganizationsResponse, null, 4))
            throw new Error('Unable to retrieve organization permissions')
        }

        return {
            organizations: userOrganizations.organizations
                .map((userOrganization) => new Organization(userOrganization)),
        }
    },
    updatePermission: async (permissions: OrganizationPermission[]): Promise<UuidV4[]> => {
        try {
            const result = await OrganizationService.updatePermissions({
                permissions,
            })
            if (!result.data) {
                throw new Error('Unable to update organization permissions')
            }

            return result.data.permissionsIds
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('update', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to update organization permissions')
            }
            throw new Error('Unable to update organization permissions')
        }
    },
})
